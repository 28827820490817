var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"light":""}},[_c('v-toolbar-title',[_vm._v(" User Administration ")]),_c('v-spacer')],1),_c('v-card',[_c('div',{staticStyle:{"margin-left":"auto","text-align":"right"}},[_c('JsonCSV',{staticClass:"btn btn-default",attrs:{"name":"admin.csv","data":_vm.userCSV}},[_c('v-btn',[_vm._v("Export CSV")])],1),_c('br'),_c('br')],1),_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-btn',{class:!_vm.onlyAdmin ? 'success' : 'error',attrs:{"x-small":""},on:{"click":_vm.toggleOnlyAdmin}},[_vm._v(_vm._s(!_vm.onlyAdmin ? "Show Only Admins" : "Turn off Only Admins Filter"))]),_c('span',{staticStyle:{"margin-left":"20px"}}),_c('v-btn',{class:!_vm.onlyPaid ? 'success' : 'error',attrs:{"x-small":""},on:{"click":_vm.toggleOnlyPaid}},[_vm._v(_vm._s(!_vm.onlyPaid ? "Show Only Paid" : "Turn off Only Paid Filter"))]),_c('span',{staticStyle:{"margin-left":"20px"}}),_c('v-btn',{class:!_vm.onlySuperUsers ? 'success' : 'info',attrs:{"x-small":""},on:{"click":_vm.toggleOnlySuper}},[_vm._v(_vm._s(!_vm.onlySuperUsers ? "Show Only Superusers" : "Don't Show Superusers"))]),_c('span',{staticStyle:{"margin-left":"20px"}}),_c('v-btn',{class:_vm.showInactive ? 'success' : 'info',attrs:{"x-small":""},on:{"click":_vm.toggleShowInactive}},[_vm._v(_vm._s(_vm.showInactive ? "Show Inactive" : "Hide Inactive"))])],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('br'),_c('v-data-table',{attrs:{"headers":_vm.headers(),"items":_vm.users.filter(function (user) { return user.User.is_superuser == _vm.onlySuperUsers; }),"search":_vm.search,"multi-sort":"","loading-text":"Loading... Please wait","options":{
        itemsPerPage: 100,
      },"items-per-page":100,"footer-props":{
        'items-per-page-options': [10, 20, 50, 100, 1000],
      }},scopedSlots:_vm._u([{key:"item.User.created_date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.User.created_date))+" ")]}},{key:"item.User.is_eligible",fn:function(ref){
      var item = ref.item;
return [(item.User.is_eligible)?_c('v-icon',{attrs:{"width":"20"}},[_vm._v("check")]):_vm._e()]}},{key:"item.User.progress_description",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.User.progress_description)+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"info",on:{"click":function($event){return _vm.editItem(item.User.id)}}},[_vm._v("View User")])]}},{key:"item.Billing",fn:function(ref){
      var item = ref.item;
return [(!!item.Billing)?_c('v-icon',{attrs:{"width":"20"}},[_vm._v("check")]):_vm._e()]}},{key:"item.User.referral",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.User.referral)+" "+_vm._s(item.User.referral_other)+" "),(!!item.User.referral_cid)?_c('div',[_vm._v(" (Referral CID "+_vm._s(item.User.referral_cid)+") ")]):_vm._e()]}},{key:"item.User.is_superuser",fn:function(ref){
      var item = ref.item;
return [(item.User.is_superuser)?_c('v-icon',{attrs:{"width":"20"}},[_vm._v("check")]):_vm._e()]}},{key:"item.User.is_contract_active",fn:function(ref){
      var item = ref.item;
return [(item.User.is_contract_active)?_c('v-icon',{attrs:{"width":"20"}},[_vm._v("check")]):_vm._e()]}}])})],1),_c('Loading',{attrs:{"message":"Processing Transaction...","showDialog":_vm.showLoading}}),_c('AdminViewUser',{attrs:{"user_id":_vm.user_id}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }