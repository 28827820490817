






















































































































import { appName, merchantURL, appUrl } from "@/env";
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IUserProfile } from "@/interfaces";
import { readAdminUsers } from "@/store/admin/getters";
import { readUserProfile } from "@/store/main/getters";
import { dispatchGetUsers } from "@/store/admin/actions";
import _ from "lodash";
import moment, { duration } from "moment-timezone";
import Apply from "../../Apply.vue";
import EditUser from "./EditUser.vue";
import UploadUserDoc from "./UploadUserDoc.vue";
import EditAddress from "./EditAddress.vue";
import MarketSegment from "./MarketSegment.vue";
import Loading from "../../Loading.vue";
import UploadReceipt from "./UploadReceipt.vue";
import AdminViewUser from "./AdminViewUser.vue";
import JsonCSV from "vue-json-csv";
@Component({
  components: {
    Apply,
    EditUser,
    UploadUserDoc,
    EditAddress,
    MarketSegment,
    Loading,
    UploadReceipt,
    AdminViewUser,
    JsonCSV,
  },
})
export default class AdminUsers extends Vue {
  public search = "";
  public dialog = false;
  public showLoading = false;
  public dialogUploadReceipt = false;
  public receipt_id = "";
  public user_id = 0;
  public headers() {
    let items = [
      {
        text: "Actions",
        value: "actions",
      },
      {
        text: "Splynx CID",
        sortable: true,
        value: "User.splynx_cid",
        align: "left",
      },
      {
        text: "Phone",
        sortable: true,
        value: "User.phone",
        align: "left",
      },
      {
        text: "Email",
        sortable: true,
        value: "User.email",
        align: "left",
      },
      {
        text: "First Name",
        sortable: true,
        value: "User.first_name",
        align: "left",
      },
      {
        text: "Last Name",
        sortable: true,
        value: "User.last_name",
        align: "left",
      },
      {
        text: "Building Admin",
        sortable: true,
        value: "User.is_building_admin",
        align: "left",
        filter: (value) => {
          if (this.onlyAdmin && value) {
            return true;
          } else {
            if (!this.onlyAdmin) {
              return true;
            }
            return false;
          }
        },
      },
      {
        text: "Address",
        sortable: true,
        value: "Address.address1",
        align: "left",
      },
      {
        text: "Signup Date",
        sortable: true,
        value: "User.created_date",
        align: "left",
      },
      {
        text: "Progress Step",
        sortable: true,
        value: "User.progress",
        align: "left",
      },
      {
        text: "Progress Status Code",
        sortable: true,
        value: "User.progress_status_code",
        align: "left",
      },
      {
        text: "Progress Description",
        sortable: true,
        value: "User.progress_description",
        align: "left",
      },
      {
        text: "Feasible",
        sortable: true,
        value: "User.is_eligible",
        align: "left",
      },
      {
        text: "Paid",
        sortable: true,
        value: "Billing",
        align: "left",
        filter: (value) => {
          if (this.onlyPaid && !!value) {
            return true;
          } else {
            if (!this.onlyPaid) {
              return true;
            }
            return false;
          }
        },
      },
      {
        text: "Active",
        sortable: true,
        value: "User.is_contract_active",
        align: "left",
        filter: (value) => {
          if (this.showInactive && !!value) {
            return true;
          } else {
            if (!this.showInactive) {
              return true;
            }
            return false;
          }
        },
      },
      {
        text: "Referral Source",
        sortable: true,
        value: "User.referral",
        align: "left",
      },
    ];
    if (this.onlySuperUsers) {
      items.push({
        text: "Super User",
        sortable: true,
        value: "User.is_superuser",
        align: "left",
        filter: (value) => {
          if (this.onlySuperUsers && value === true) {
            return true;
          } else if (!this.onlySuperUsers && !value) {
            return true;
          }
        },
      });
    }
    return items;
  }

  public editedIndex = -1;
  public editedItem = {
    id: -1,
  };
  public editedUser = {};
  public defaultItem = {};
  public rebate = "";
  public rebate_comment = "";

  public recurDate = "";

  public installationDate = "";
  public duration_hour = 2;
  public duration_minute = 0;
  public is_auto_pay = false;
  public rebillSummary = {};
  public userToEdit = {};
  public num_recur = 1;
  public onlyAdmin = false;
  public onlyPaid = false;
  public onlySuperUsers = false;
  public showInactive = true;
  public userCSV = [];
  $refs!: Vue["$refs"] & {
    edit_address: typeof EditAddress;
    upload_doc: typeof UploadUserDoc;
    edit_info: typeof EditUser;
    upload_receipt: typeof UploadReceipt;
  };

  created() {
    if (location.host.startsWith("dev")) {
      document.title = "Dev: User Administration";
    } else {
      document.title = "User Administration";
    }
  }
  get hours() {
    return [...Array(24).keys()];
  }
  get minutes() {
    return [...Array(60).keys()];
  }
  get users() {
    const users = readAdminUsers(this.$store).filter(
      (user) => !user.is_superuser
    );
    console.log(users);
    return users;
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
    this.userCSV = this.users.map((user) => {
      const item = {};
      this.headers().forEach((header) => {
        item[header.text] = _.get(user, header.value, "");
        console.log(header.text);
      });
      return item;
    });
  }

  get_promo_desc(editedUser, item) {
    const promo_id = item.promo_id;
    let promo_desc = "";
    editedUser.promos.forEach((promo) => {
      if (promo.id == promo_id) {
        promo_desc = `${promo.description}`;
      }
    });
    return promo_desc;
  }
  get_service_name(editedUser, item) {
    const service_id = item.service_id;
    let service_name = "";
    editedUser.services.forEach((service) => {
      if (service.id == service_id) {
        service_name = `${service.name} (${service.speed} ${service.speed_unit})`;
      }
    });
    return service_name;
  }
  get_terms(user, item) {
    if (user.activation_date) {
      let date1 = this.formatDateOnly(moment(user.activation_date));
      if (item.contract_start_date > user.activation_date) {
        date1 = this.formatDateOnly(moment(item.contract_start_date));
      }
      let date2 = this.formatDateOnly(
        moment(item.contract_start_date)
          .add(item.contract_length, "months")
          .subtract(1, "day")
      );
      if (item.is_upgrade) {
        date2 = this.formatDateOnly(
          moment(user.activation_date)
            .add(item.contract_length, "months")
            .subtract(1, "day")
        );
      }
      return `${date1} to ${date2}`;
    } else {
      return `${user.contract_length} Months`;
    }
  }

  async editItem(user_id) {
    this.user_id = user_id;
  }

  getPmethod(item) {
    if (item.billing_id) {
      const bills = _.get(this.editedUser, "bills", []);
      return _.get(
        _.first(bills.filter((bill) => bill.id == item.billing_id)),
        "ptype",
        ""
      );
    } else if (item.rebill_id) {
      const bills = _.get(this.editedUser, "rebills", []);
      return _.get(
        _.first(bills.filter((bill) => bill.id == item.rebill_id)),
        "ptype",
        ""
      );
    }
  }

  formatDate(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MMM DD, YYYY HH:mm:ss");
    }
    return "";
  }
  formatDateOnly(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MMM DD, YYYY");
    }
    return "";
  }
  formatMonthSplynx(splynx_items_json) {
    if (splynx_items_json) {
      const items = _.get(JSON.parse(splynx_items_json), "items", []);
      let date = null;
      items.forEach((item) => {
        if (!date || (item.period_from && moment(item.period_from) > date)) {
          date = moment(item.period_from);
        }
      });
      if (date) {
        return moment.utc(date).tz("Asia/Manila").format("MM");
      } else {
        return null;
      }
    }
    return "";
  }
  formatMonth(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MM");
    }
    return "";
  }
  formatYear(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("YYYY");
    }
    return "";
  }

  formatDoc(item) {
    const type = _.get(item, "document_type", "id");
    if (type) {
      return type.toUpperCase();
    } else {
      return "";
    }
  }
  close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({ id: -1 }, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  getDocLink(user, doc_id) {
    return `/api/v1/users/document/${user.user.id}/${doc_id}/${user.token}/`;
  }

  getPDFLink(user, type, doc_id) {
    return `/api/v1/users/pdf/${user.user.id}/${type}/${doc_id}/${user.token}/`;
  }

  isPDF(name) {
    if (name.toString().toLowerCase().includes("pdf")) {
      return true;
    }
    return false;
  }

  getApiUrl() {
    return appUrl;
  }

  toggleOnlyAdmin() {
    this.onlyAdmin = !this.onlyAdmin;
  }

  toggleOnlySuper() {
    this.onlySuperUsers = !this.onlySuperUsers;
  }

  toggleOnlyPaid() {
    this.onlyPaid = !this.onlyPaid;
  }
  public async toggleShowInactive() {
    this.showInactive = !this.showInactive;
  }
}
